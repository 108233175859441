<template>
  <div :class="[styled]" class="flex flex-col absolute inset-0 text-white">
    <div v-if="preCaption" data-aos="fade-right" class="mb-1.5 sm:mb-2">
      <CHeading :heading="preCaptionHeading" :class=[preCaptionStyled]>
        <span class="px-2 leading-tight box-decoration-clone text-lg sm:text-2xl bg-[#002233d0] tracking-normal">{{ props.preCaption }}</span>
      </CHeading>
    </div>
    <div v-if="caption" data-aos="fade-right">
      <CHeading data-aos-delay="100" :heading="captionHeading" :class="[captionStyled]">
        <span class="px-2 leading-tight box-decoration-clone font-black text-2xl sm:text-4xl bg-[#002233d0] tracking-normal">{{ props.caption }}</span>
      </CHeading>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  layout?: 'normal' | 'center'
  preCaption?: string
  preCaptionHeading?: 'div' | 'h1' | 'h2' | 'h3'
  caption?: string
  captionHeading?: 'h1' | 'h2' | 'h3' | 'div'
}

const layoutMap = {
  'normal': 'ps-6 pe-10 2xl:px-0 container sm:max-w-page pb-20 justify-end items-start',
  'center': 'px-4 justify-center items-center text-center container sm:max-w-7xl',
}

const props = withDefaults(defineProps<Props>(), {
  layout: 'normal',
  preCaption: '',
  preCaptionHeading: 'div',
  caption: '',
  captionHeading: 'h1',
})

const captionStyled = props.caption ? 'block' : 'hidden'
const preCaptionStyled = props.preCaption ? 'block' : 'hidden'

const styled = [
  layoutMap[props.layout],
].join(' ')
</script>
